import React, {useCallback, useEffect, useState, useRef} from 'react';
import 'datatables.net';
import 'datatables.net-responsive';
import {GET_COLLECTION_AGGREGATE_DATA, SEARCH_COLLECTION_URL} from '../../../api';
import $ from "jquery";
import {capitalize} from "../../../utils/capitalize";
import moment from 'moment';
import formatNumber from "../../../utils/formatNumber";
import {getWasteReport, fumigation, getFumigationReport} from "../../../api/app";
import cogoToast from "cogo-toast";
import jsonExcel from "../../../utils/jsonExcel";
import {Marker, GoogleMap, useLoadScript} from "@react-google-maps/api";
const mapStyle = require("../../../data/map.json");

let searchableFields = [
    { field: 'status', isNumeric: false },
    { field: 'user.first_name', isNumeric: false },
    { field: 'user.last_name', isNumeric: false },
    { field: 'user.mobile_number', isNumeric: false }
]

// Google Map
const GMap = ({ coordinate }) => {
    const mapRef = useRef(null);

    const onLoad = useCallback(map => {
        mapRef.current = map;
        const zoomLevel = 15;
        map.setCenter(coordinate);
        map.setZoom(zoomLevel);
    }, [coordinate]);

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.panTo(coordinate);
        }
    }, [coordinate]);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyBcwPZxow61r9xp4GpD5CJDsJzz1WEXW8Y",
        libraries: ["drawing"]
    });

    return (
        <>
            {!isLoaded ? (
                <h6 style={{ marginTop: 40 }}>Loading Map...</h6>
            ) : (
                <div>
                    <GoogleMap
                        mapContainerClassName="map-container"
                        center={coordinate}
                        onLoad={onLoad}
                        zoom={10}
                        options={{ styles: mapStyle }}
                    >
                        <Marker position={coordinate} />
                    </GoogleMap>
                </div>
            )}
        </>
    );
};


const Fumigation = (props) => {

    document.title = "Fumigation";

    const token = localStorage.getItem('jwtToken');
    const [openMap, setOpenMap] = useState(false);
    const [fumigationId, setFumigationId] = useState(null);
    const [coordinate, setCoordinate] = useState({});
    const [stateReady, setStateReady] = useState(false);
    const [reset, setReset] = useState(new Date().getTime());

    useEffect(() => {
        getFumigationReport().then((response) => {
            if(!response.error) {
                $('#total-fumigation').text(formatNumber(response.payload.total_fumigation));
                $('#total-paid').text(response.payload.total_paid);
                $('#total-expired').text(response.payload.total_expired);
            }
        });
    }, [reset]);

    useEffect(() => {
        let table = $('#td-waste').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Fumigation Report</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_COLLECTION_AGGREGATE_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "fumigation",
                        searchableFields,
                        pageSize: data.start,
                        format: 'json',
                        populate: [
                            {
                                model: "user",
                                path: "user"
                            },
                            {
                                model: "property",
                                path: "address.property"
                            }
                        ],
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {
                    className: 'dt-control',
                    orderable: false,
                    data: null,
                    defaultContent: '',
                },
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function () {
                        return null;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return `<a href="#/user/${data.user._id}" class="text-dark">${capitalize(data.user.first_name)} ${capitalize(data.user.last_name)}</a>`
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.user.mobile_number
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return {
                            expired : `<span class="badge badge-danger tx-white">Expired</span>`,
                            paid : `<span class="badge badge-success tx-white">Paid</span>`,
                            pending : `<span class="badge badge-primary tx-white">Pending</span>`,
                            awaiting : `<span class="badge badge-secondary tx-white">Awaiting</span>`,
                            credit : `<span class="badge badge-warning tx-white">Credit</span>`
                        }[data.status];
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return capitalize(data.address.meta.suburb);
                    },
                    "targets": 4
                },
                {
                    "render": function (data) {
                        return `<span>${moment(data.date.iso).format("Do MMM, YYYY")}</span>`;
                    },
                    "targets": 5
                }
            ]
        });

        table.on('click', '.view_user', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();
            props.history.push(`/user/${extract_td.raw._id}`);
        });

        $('#td-waste tbody').on('click', 'td.dt-control', function () {
            let tr = $(this).closest('tr');
            let row = table.row(tr);

            if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
            } else {
                // Open this row
                row.child(format(row.data())).show();
                tr.addClass('shown');
            }
        });

        function format(d) {
            // `d` is the original data object for the row
            return (
                '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">' +
                '<tr>' +
                '<td>Address:</td>' +
                '<td>' +
                `${d.raw.address.unit_number}, ${d.raw.address.meta.street_address}` +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Type:</td>' +
                '<td>' +
                d.raw.type.map(capitalize).join(', ') +
                '</td>' +
                '<tr>' +
                '<tr>' +
                '<td>Note:</td>' +
                '<td>' +
                capitalize(d.raw.description) +
                '</td>' +
                '</tr>' +
                '<tr>' +
                '<td>Action:</td>' +
                '<td>'+`<nav class="nav nav-icon-only"><a href="#" data-target="${d.raw.address.location.coordinates}" class="nav-link view-map"><i class="fa fa-map"></i> View Map</a> ${d.raw.status === "pending" ? `<a href="#" data-id="${d.raw._id}" class="nav-link view-fumigation"><i class="fa fa-pencil"></i> Accept</a> <a href="#" data-id="${d.raw._id}" class="nav-link decline-fumigation"><i class="fa fa-trash"></i> Decline</a>` : `<span class="nav-link"><i class="fa fa-ban"></i> No Action</span>`}</nav>`+'</td>' +
                '</tr>' +
                '</table>'
            );
        }

        table.on('click', '.view-map', function(e) {
            e.preventDefault();
            let data = $(this).attr("data-target");

            let [lat, lng] = data.split(',').map(Number);

            setCoordinate({lat, lng});

            $("#modalMap").modal({ backdrop: 'static', focus: false, show: true });
            setOpenMap(true);
        });

        table.on('click', '.view-fumigation', function(e) {
            e.preventDefault();

            initializeCollectorSearch();

            let data_id = $(this).attr("data-id");

            setFumigationId(data_id);

            $('#fumigation_form').parsley();
            $("#modalFumigation").modal({ backdrop: 'static', focus: false, show: true });
        });

        table.on('click', '.decline-fumigation', function(e) {
            e.preventDefault();

            let data_id = $(this).attr("data-id");

            $(".data-message").text(`Are you sure, you want to delete, this data?`);
            $('#delete-data').unbind("click").click(function(){

                $("#modalConfirm").modal("hide");
                $(".modal-backdrop").remove();

                const options = {
                    position: "top-right",
                    hideAfter: 0
                };

                let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

                let data = {
                    id: data_id,
                }

                fumigation("delete", data).then((result) => {
                    hide();
                    if(!result.error) {
                        reloadTable();
                        cogoToast.success("Waste successfully removed.", {position: "top-right"});
                    }
                });
            });

            $("#modalConfirm").modal({ backdrop: 'static', focus: false, show: true });
        });

        return () => {
            table.destroy();
        }

    }, []);

    const reloadTable = () => {
        setReset(new Date().getTime());
        $('#td-waste').DataTable().ajax.reload(null, false);
    };

    const resetForm = (id, form) => {
        const parsley = $(`#${form}`).parsley();
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        parsley.reset();
        parsley.destroy();
        $(".modal-backdrop").remove();
    };

    const initializeCollectorSearch = () => {
        let modalFumigation = $('#modalFumigation');
        $('#collector-select2').select2({
            placeholder: 'Select garbage collector or type name',
            allowClear: true,
            width:"100%",
            maximumSelectionLength: 1,
            dropdownParent: modalFumigation,
            ajax: {
                url: SEARCH_COLLECTION_URL,
                dataType: 'json',
                type: "POST",
                headers: {
                    "Authorization": token,
                },
                data: function(query) {
                    return {
                        query: query,
                        collection: "collector"
                    };
                },
                processResults: function(data) {
                    return {
                        results: $.map(data, function(item) {
                            return {
                                text: capitalize(item.name),
                                id: item._id
                            }
                        })
                    };
                }
            }
        }).on('select2:select', function(e){
            $(".select2-search__field").css({display: "none"});
        }).on('select2:unselect', function(e) {
            $(".select2-search__field").css({display: "inline-block"});
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let amount = $('#amount').val();
        setStateReady(true);

        let data = {
            fumigation_id: fumigationId,
            amount: amount,
            type: "collector",
            collector_id: $('#collector-select2').val()[0]
        };

        fumigation("put", data).then((result) => {
            setStateReady(false);
            if(!result.error) {
                cogoToast.success("Data successfully created.", {position: "top-right", hideAfter: 5});
                resetForm("modalFumigation", "fumigation_form");
                reloadTable();
            }
        });
    }

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item active" aria-current="page">Inquires</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Fumigation</h4>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-sm-6 col-lg-4 mg-t-10 mg-lg-t-0">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Total Fumigation</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 id="total-fumigation" className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">0</h3>
                            </div>
                            <span id="date-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">Total Fumigation</span>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-4 mg-t-10 mg-sm-t-0">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-success tx-semibold mg-b-8">Total Paid</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 id="total-paid" className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">0</h3>
                            </div>
                            <span id="month-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">Paid Fumigation</span>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-4 mg-t-10 mg-sm-t-0">
                        <div className="card card-body">
                            <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-danger tx-semibold mg-b-8">Total Pending</h6>
                            <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                <h3 id="total-expired" className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">0</h3>
                            </div>
                            <span id="month-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">Pending Fumigation</span>
                        </div>
                    </div>

                    <div className="col-md-12 mg-t-10">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Fumigation</h6>
                                <div className="d-flex tx-18">
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        reloadTable()
                                    }} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></a>
                                </div>
                            </div>
                            <div className="card-body">
                                <table id="td-waste" className="table">
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th className="wd-20p">Name</th>
                                        <th className="wd-20p">Mobile Number</th>
                                        <th className="wd-20p">Status</th>
                                        <th className="wd-20p">Area</th>
                                        <th className="wd-15p">Date Issued</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalMap" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                            <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal"
                               aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </a>
                            <div className="media-body"><a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></a>
                                <h4 className="tx-18 tx-sm-20 mg-b-2">View Map</h4>
                                <p className="tx-13 tx-color-02 mg-b-0">Map information.</p>
                            </div>
                        </div>
                        <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                            {openMap ? <GMap coordinate={coordinate}/> : null}
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalConfirm" tabIndex="-1" role="dialog" aria-labelledby="confirmation"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content tx-14">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLabel6">Delete Confirm?</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p className="mg-b-0 data-message">Empty</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" id="delete-data" className="btn btn-success btn-block">Yes, Delete</button>
                            <button type="button" className="btn btn-danger btn-block mt-0" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalFumigation" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered wd-sm-650" role="document">
                    <div className="modal-content">
                        <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                            <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </a>
                            <div className="media-body"><a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" onClick={resetForm.bind(null, "modalFumigation", "fumigation_form")} aria-label="Close"><span aria-hidden="true">×</span></a><h4
                                className="tx-18 tx-sm-20 mg-b-2">Fumigation</h4>
                                <p className="tx-13 tx-color-02 mg-b-0">Fumigation information.</p></div>
                        </div>
                        <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                            <form id="fumigation_form" className="parsley-style-1" onSubmit={handleSubmit.bind()}>
                                <div id="collectorWrapper" className="form-group parsley-input">
                                    <div className="d-flex justify-content-between">
                                        <label>Garbage Collector<span className="tx-danger">*</span></label>
                                    </div>
                                    <select className="form-control" id="collector-select2" data-width="100%"
                                            multiple="multiple"
                                            data-parsley-class-handler="#collectorWrapper"
                                            data-parsley-errors-container="#collectorWrapper"
                                            autoComplete="off"
                                            required>
                                    </select>
                                </div>

                                <div id="amountWrapper" className="form-group parsley-input">
                                    <label>Amount<span className="tx-danger">*</span></label>
                                    <input id="amount" type="number" className="form-control"
                                           placeholder="Enter amount"
                                           autoComplete="none"
                                           data-parsley-class-handler="#amountWrapper" required/>
                                </div>

                                {stateReady ? <button disabled className="btn btn-success btn-block"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>Please Wait</button> : <button className="btn btn-success btn-block">Proceed</button>}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Fumigation;
