import React, {useEffect, useState} from 'react';
import 'datatables.net';
import 'datatables.net-responsive';
import {GET_COLLECTION_AGGREGATE_DATA} from '../../../api';
import $ from "jquery";
import {capitalize} from "../../../utils/capitalize";
import cogoToast from "cogo-toast";
import moment from "moment/moment";
import "ionicons";

const Rate = (props) => {

    document.title = "Rating";

    const token = localStorage.getItem('jwtToken');

    useEffect(() => {
        let table = $('#td-rating').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Rating</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_COLLECTION_AGGREGATE_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "feedback",
                        searchableFields: [
                            { field: 'title', isNumeric: false },
                            { field: 'user.first_name', isNumeric: false },
                            { field: 'user.last_name', isNumeric: false },
                            { field: 'user.mobile_number', isNumeric: true },
                            { field: 'collector.name', isNumeric: false }
                        ],
                        pageSize: data.start,
                        format: 'json',
                        populate: [
                            {
                                path: 'user',
                                model: 'user'
                            },
                            {
                                path: 'collector',
                                model: 'collector'
                            }
                        ],
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {
                    className: 'dt-control',
                    orderable: false,
                    data: null,
                    defaultContent: '',
                },
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function () {
                        return null;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return `<a href="#/user/${data.user._id}" target="_blank" class="link-02">${capitalize(data.user.first_name)} ${capitalize(data.user.last_name)}</a>`;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return {
                            1 : `<div class="d-flex">
                                    <strong class="tx-12 tx-rubik mg-r-5">1.0</strong>
                                      <div>
                                          <ion-icon name="star" class="tx-orange"></ion-icon>
                                          <ion-icon name="star" class="tx-gray-300"></ion-icon>
                                          <ion-icon name="star" class="tx-gray-300"></ion-icon>
                                          <ion-icon name="star" class="tx-gray-300"></ion-icon>
                                          <ion-icon name="star" class="tx-gray-300"></ion-icon>
                                      </div>
                                    </div>
                                </div>`,
                            2 : `<div class="d-flex">
                                    <strong class="tx-12 tx-rubik mg-r-5">2.0</strong>
                                      <div>
                                          <ion-icon name="star" class="tx-orange"></ion-icon>
                                          <ion-icon name="star" class="tx-orange"></ion-icon>
                                          <ion-icon name="star" class="tx-gray-300"></ion-icon>
                                          <ion-icon name="star" class="tx-gray-300"></ion-icon>
                                          <ion-icon name="star" class="tx-gray-300"></ion-icon>
                                      </div>
                                    </div>
                                </div>`,
                            3 : `<div class="d-flex">
                                    <strong class="tx-12 tx-rubik mg-r-5">3.0</strong>
                                      <div>
                                          <ion-icon name="star" class="tx-orange"></ion-icon>
                                          <ion-icon name="star" class="tx-orange"></ion-icon>
                                          <ion-icon name="star" class="tx-orange"></ion-icon>
                                          <ion-icon name="star" class="tx-gray-300"></ion-icon>
                                          <ion-icon name="star" class="tx-gray-300"></ion-icon>
                                      </div>
                                    </div>
                                </div>`,
                            4 : `<div class="d-flex">
                                    <strong class="tx-12 tx-rubik mg-r-5">4.0</strong>
                                      <div>
                                          <ion-icon name="star" class="tx-orange"></ion-icon>
                                          <ion-icon name="star" class="tx-orange"></ion-icon>
                                          <ion-icon name="star" class="tx-orange"></ion-icon>
                                          <ion-icon name="star" class="tx-orange"></ion-icon>
                                          <ion-icon name="star" class="tx-gray-300"></ion-icon>
                                      </div>
                                    </div>
                                </div>`,
                            5 : `<div class="d-flex">
                                    <strong class="tx-12 tx-rubik mg-r-5">5.0</strong>
                                      <div>
                                          <ion-icon name="star" class="tx-orange"></ion-icon>
                                          <ion-icon name="star" class="tx-orange"></ion-icon>
                                          <ion-icon name="star" class="tx-orange"></ion-icon>
                                          <ion-icon name="star" class="tx-orange"></ion-icon>
                                          <ion-icon name="star" class="tx-orange"></ion-icon>
                                      </div>
                                    </div>
                                </div>`
                        }[data.rate];
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return `<div class="d-flex">
                            <div class="avatar wd-20 ht-20"><img src="${data.collector.logo_uri}" class="rounded-circle" alt="logo"></div>
                            <span class="pd-x-5">${capitalize(data.collector.name)}</span>
                        </div>`;
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        return moment(data.date.str).format("Do MMM, YYYY");
                    },
                    "targets": 4
                },
                {
                    "render": function () {
                        return `<nav class="nav nav-icon-only"><a href="#" class="nav-link view-transaction">---</nav>`
                    },
                    "targets": 5
                }
            ]
        });

        $('#td-rating tbody').on('click', 'td.dt-control', function () {
            let tr = $(this).closest('tr');
            let row = table.row(tr);

            if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('shown');
            } else {
                // Open this row
                row.child(format(row.data())).show();
                tr.addClass('shown');
            }
        });

        function format(d) {
            // `d` is the original data object for the row
            return (
                '<table cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;">' +
                '<tr>' +
                '<td>Feedback:</td>' +
                '<td>' +
                d.raw.feedback +
                '</table>'
            );
        }

        return () => {
            table.destroy();
        }

    }, []);


    const reloadTable = () => {
        $('#td-rating').DataTable().ajax.reload(null, false);
    };

    const handleTabNext = () => {
        $('.nav-line .active').parent().next('li').find('a').trigger('click');
    };

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item active" aria-current="page">Rating</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Rating</h4>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12 mg-t-10">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Ratings</h6>
                                <div className="d-flex tx-18">
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        $('#td-rating').DataTable().ajax.reload()
                                    }} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></a>
                                </div>
                            </div>
                            <div className="card-body">
                                <table id="td-rating" className="table">
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th className="wd-20p">Customer</th>
                                        <th className="wd-20p">Rate</th>
                                        <th className="wd-20p">Collector</th>
                                        <th className="wd-20p">Date</th>
                                        <th className="wd-20p">Action</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalConfirm" tabIndex="-1" role="dialog" aria-labelledby="confirmation" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content tx-14">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLabel6">Delete Confirm?</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p className="mg-b-0 data-message">Empty</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" id="delete-data" className="btn btn-success btn-block">Yes, Delete</button>
                            <button type="button" className="btn btn-danger btn-block mt-0" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Rate;
