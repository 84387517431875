import React, {useEffect, useState, useRef, useMemo} from 'react';
import 'datatables.net';
import 'datatables.net-responsive';
import {GET_COLLECTION_AGGREGATE_DATA} from '../../../api';
import $ from "jquery";
import cogoToast from "cogo-toast";
import moment from "moment";
import {capitalize} from "../../../utils/capitalize";
import {getPreSignedUrl, media, uploadPhoto} from "../../../api/app";
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import textEncoder from "../../../utils/textEncoder";
const Buffer = require('buffer/').Buffer;

const Media = () => {

    document.title = "Media";

    const token = localStorage.getItem('jwtToken');

    const quillRef = useRef();

    const [stateReady, setStateReady] = useState(false);
    const [editForm, setEditForm] = useState(false);
    const [editId, setEditId] = useState(0);
    const [cover_image, set_cover_image] = useState(null);
    const [value, setValue] = useState(null);

    const imageHandler = () => {
        const editor = quillRef.current.getEditor();
        const input = document.createElement("input");
        input.setAttribute("type", "file");
        input.setAttribute("accept", "image/*");
        input.click();

        input.onchange = async () => {
            const file = input.files[0];
            if (/^image\//.test(file.type)) {
                const res = await S3Upload(); // upload data into server or aws or cloudinary
                const url = res?.Location;
                editor.insertEmbed(editor.getSelection(), "image", url);
            } else {
                cogoToast.error('You could only upload images.', {hideAfter: 3});
            }
        }
    }

    const modules = useMemo(
        () => ({
            toolbar: {
                container: [
                    [{ 'header': '1'}, {'header': '2'}],
                    [{size: []}],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                    ['image'],
                    ['clean']
                ],

                handlers: {
                    image: imageHandler.bind(),
                },
                history: {
                    delay: 500,
                    maxStack: 100,
                    userOnly: true,
                },
            },
        }),
        []
    );

    useEffect(() => {
        let table = $('#media').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Media Report</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_COLLECTION_AGGREGATE_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "media",
                        searchableFields: [
                            { field: 'title', isNumeric: false },
                            { field: 'description', isNumeric: false }
                        ],
                        pageSize: data.start,
                        format: 'json',
                        populate: [
                            {
                                path: "author",
                                model: "author"
                            }
                        ],
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.title;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return capitalize(data.type);
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return data.author.name;
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return moment(data.dateCreated).format("Do MMM, YYYY");
                    },
                    "targets": 3
                },
                {
                    "render": function () {
                        return `<nav class="nav nav-icon-only"><a href="#" class="nav-link edit_media"><i class="fa fa-pencil"></i> Edit</a> <a href="#" class="nav-link delete_media"><i class="fa fa-trash-alt"></i> Delete</a></nav>`
                    },
                    "targets": 4
                }
            ]
        });

        table.on('click', '.delete_media', function(e) {
            e.preventDefault();
            let extract_td = table.row($(this).closest('tr')).data();
            $(".data-message").text(`Are you sure, you want to delete, ${capitalize(extract_td.raw.title)}?`);
            $('#delete-data').unbind("click").click(function(){

                $("#modalConfirm").modal("hide");
                $(".modal-backdrop").remove();

                const options = {
                    position: "top-right",
                    hideAfter: 0
                };

                let {hide} = cogoToast.loading('Please wait... contacting to server.', options);

                let data = {
                    id: extract_td.raw._id,
                }

                media("delete", data).then((result) => {
                    hide();
                    if(!result.error) {
                        reloadTable();
                        cogoToast.success("Media successfully removed.", {position: "top-right"});
                    }
                });
            });

            $("#modalConfirm").modal({ backdrop: 'static', focus: false, show: true });
        });

        table.on('click', '.edit_media', function(e) {
            e.preventDefault();

            let title = $("#title");
            let type = $("#type");

            $('#cover_image').prop("required", false);

            let extract_td = table.row($(this).closest('tr')).data();

            setEditForm(true);
            setEditId(extract_td.raw._id);

            $('#media_form').parsley();

            type.prop("disabled", true).prop("required", false);

            title.val(extract_td.raw.title);
            type.val(extract_td.raw.type);
            setValue(atob(extract_td.raw.description));
            $("#modalMedia").modal("show");
        });

        return () => {
            table.destroy();
        }

    }, []);

    const reloadTable = () => {
        $('#media').DataTable().ajax.reload(null, false);
    };

    const handleShowModal = () => {
        setEditForm(false);
        $('#cover_image').prop("disabled", false).prop("required", true);
        $('#media_form').parsley();
        $("#modalMedia").modal({ backdrop: 'static', focus: false, show: true });
    };

    const handleSubmit = async(e) => {
        e.preventDefault();

        let title = $('#title').val();

        let description = null;

        try {
            description = textEncoder(value);
        } catch (err) {
            return cogoToast.error("Something wrong with your description", {hideAfter: 5});
        }

        setStateReady(true);

        if(editForm) {
            let data = {
                id: editId,
                title,
                img_uri : cover_image !== null ? await S3Upload() : null,
                description
            };

            console.log(data);

            media("put", data).then((result) => {
                setStateReady(false);

                if(!result.error) {
                    cogoToast.success("Data successfully updated.", {position: "top-right", hideAfter: 5});
                    resetForm("modalMedia", "media_form");
                    reloadTable();
                }
            });

            return false;
        }

        let data = {
            title,
            description,
            img_uri: await S3Upload(),
            type: $('#type').val(),
            author: "6248b12d743135c437338b14"
        };

        media("post", data).then((result) => {
            setStateReady(false);
            if(!result.error) {
                setValue(null);
                cogoToast.success("Data successfully created.", {position: "top-right", hideAfter: 5});
                resetForm("modalMedia", "media_form");
                reloadTable();
            }
        });
    };

    const resetForm = (id, form) => {
        const parsley = $(`#${form}`).parsley();
        $(':input',`#${form}`)
            .not(':button, :submit, :reset, :hidden')
            .val('')
            .prop('checked', false)
            .prop('selected', false);
        $(`#${id}`).modal("hide");
        parsley.reset();
        parsley.destroy();
        $(".modal-backdrop").remove();
    };

    const S3Upload = () => {
        return getPreSignedUrl().then(async (response) => {
            if (response.error) {
                return false;
            }

            const config = {
                headers: {
                    'Content-Type': 'image/jpeg; charset=utf-8',
                    'x-amz-acl': 'public-read'
                }
            };

            const buffer = new Buffer.from(cover_image.replace(/^data:image\/\w+;base64,/, ""), 'base64')

            return uploadPhoto(response.payload.url, buffer, config).then((result) => {
                if (!result.error) {
                    return result.payload.config.url.replace(/\?.*$/g,"");
                }

                return null;
            });
        })
    };

    const handleFileUpload = (event) => {
        if(event.target.files[0] !== undefined && $('#cover_image').parsley().isValid() !== false) {
            let reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = () => {
                set_cover_image(reader.result);
            };
            reader.onerror = () => {
                set_cover_image(null);
            };
        }
    }

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item active" aria-current="page">Media</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Media</h4>
                    </div>
                    <div className="d-none d-md-block">
                        <button className="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5 outline-none" onClick={handleShowModal.bind()}><i className="wd-10 mg-r-5 fa fa-plus"></i> Add New</button>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-md-12 mg-t-10">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Media List</h6>
                                <div className="d-flex tx-18">
                                    <a href="#" onClick={(e) => {e.preventDefault(); $('#media').DataTable().ajax.reload()}} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></a>
                                </div>
                            </div>
                            <div className="card-body">
                                <table id="media" className="table">
                                    <thead>
                                    <tr>
                                        <th className="wd-20p">Name</th>
                                        <th className="wd-10p">Type</th>
                                        <th className="wd-10p">Author</th>
                                        <th className="wd-10p">Date</th>
                                        <th className="wd-10p">Action</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalMedia" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </a>
                                <div className="media-body"><a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" onClick={resetForm.bind(null, "modalMedia", "media_form")} aria-label="Close"><span aria-hidden="true">×</span></a><h4 className="tx-18 tx-sm-20 mg-b-2">{editForm ? "Edit Media" : "Create Media"}</h4><p className="tx-13 tx-color-02 mg-b-0">{editForm ? "Edit" : "Create"} media information.</p></div>
                            </div>
                            <div className="modal-body pd-sm-t-30 pd-sm-b-40 pd-sm-x-30">
                                <form id="media_form" className="parsley-style-1" onSubmit={handleSubmit.bind()}>
                                    <div id="titleWrapper" className="form-group parsley-input">
                                        <label>Title<span className="tx-danger">*</span></label>
                                        <input id="title" type="text" className="form-control"
                                               placeholder="Enter title"
                                               autoComplete="none"
                                               data-parsley-class-handler="#titleWrapper" required/>
                                    </div>

                                    <div id="typeWrapper" className="form-group parsley-input">
                                        <label>Type<span className="tx-danger">*</span></label>
                                        <select className="custom-select" id="type" required>
                                            <option value="" disabled selected>Select</option>
                                            <option value="press">Press</option>
                                            <option value="blog">Blog</option>
                                        </select>
                                    </div>

                                    <div id="coverWrapper" className="form-group parsley-input">
                                        <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Cover Image</label>
                                        <input id="cover_image" type="file" className="form-control"
                                               onChange={handleFileUpload.bind()}
                                               data-parsley-filemaxmegabytes="1"
                                               data-parsley-trigger="change"
                                               data-parsley-filemimetypes="image/jpeg, image/jpg, image/png"
                                               accept="image/jpeg, image/jpg, image/png"
                                               data-parsley-class-handler="#coverWrapper"
                                        />
                                    </div>

                                    <div id="descriptionWrapper" className="form-group parsley-input">
                                        <label className="tx-10 tx-uppercase tx-medium tx-spacing-1 mg-b-5">Description</label>
                                        <ReactQuill
                                            id={"quill-id"}
                                            theme="snow"
                                            value={value}
                                            onChange={setValue}
                                            modules={modules}
                                            ref={quillRef}
                                            placeholder={'Write something...'}
                                        />
                                    </div>

                                    {stateReady ? <button disabled className="btn btn-success btn-block"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>Please Wait</button> : editForm ? <button className="btn btn-success btn-block">Update Media</button> : <button className="btn btn-success btn-block">Create Media</button>}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalConfirm" tabIndex="-1" role="dialog" aria-labelledby="confirmation" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                        <div className="modal-content tx-14">
                            <div className="modal-header">
                                <h6 className="modal-title" id="exampleModalLabel6">Delete Confirm?</h6>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className="mg-b-0 data-message">Empty</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" id="delete-data" className="btn btn-success btn-block">Yes, Delete</button>
                                <button type="button" className="btn btn-danger btn-block mt-0" data-dismiss="modal">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Media;
